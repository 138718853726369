.profile__description {
  margin: 0;
  padding: 0;
  font-size: 18px;
  line-height: 1.22;
  font-weight: 400;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
/*
@media screen and (max-width: 768px) {
  .profile__description {
    font-size: 16px;
  }
} */

@media screen and (max-width: 525px) {
  .profile__description {
    font-size: 14px;
    text-align: center;
  }
}
