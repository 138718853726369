.figure__close-btn {
  position: absolute;
  right: -50px;
  top: -50px;
}



@media screen and (max-width: 525px) {
  .figure__close-btn {
    right: -40px;
    top: -40px;
  }
}
