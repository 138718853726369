@import url(./_centered/form__title_centered.css);

.form__title {
  margin: 0 0 47px;
  padding: 0;
  font-size: 24px;
  line-height: 29px;
  font-weight: 900;
}


@media screen and (max-width: 525px) {
  .form__title {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 36px;
  }
}
