@import url(./__container/page__container.css);

.page {
  margin: 0;
  padding: 0;
  background-color: #000000;
  color: #ffffff;

  font-family: 'Inter', Arial, Helvetica, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
}
