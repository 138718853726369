.form__input {
  margin: 0 0 6px;
  padding: 0;
  width: 100%;
  font-size: 14px;
  line-height: 17px;
  min-height: 27px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background-color: transparent;
}


.form__input:invalid {
  border-bottom: 1px solid #FF0000;
}

@media screen and (max-width: 525px) {
  .form__input {
    font-size: 12px;
    line-height: 15px;
    min-height: 24px;
  }
}

