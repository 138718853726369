.card__image {
  display: block;
  width: 100%;
  height: 282px;
  object-fit: cover;
  object-position: center;
}

/* добавил медиа-запросы для сохранения квадратности картинок при разных исходных пропроциях */
.card__image:hover {
  cursor: pointer;
}

@media screen and (max-width: 920px) {
  .card__image {
    height: calc((100vw - 74px) / 3);
  }
}

@media screen and (max-width: 824px) {
  .card__image {
    height: calc((100vw - 56px) / 2);
  }
}

@media screen and (max-width: 556px) {
  .card__image {
    height: calc(100vw - 38px);
  }
}
