.card__title {
  margin: 0;
  padding: 0;
  font-size: 24px;
  line-height: 29px;
  font-weight: 900;
  max-width: 85%;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
