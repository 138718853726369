.profile__content {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-right: 10px;
  max-width: calc(100% - 160px);
  width: calc(100% - 160px);
}


@media screen and (max-width: 768px) {
  .profile__content {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media screen and (max-width: 525px) {
  .profile__content {
    align-items: center;
    max-width: 100%;
    width: 100%;
    margin-right: 0;
    margin-bottom: 32px;
  }
}
