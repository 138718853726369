.profile__edit-btn {
  background-color: #000000;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  min-width: 24px;
  height: 24px;
  background-image: url(../../../images/profile__edit-btn.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 10px;
  box-shadow: none;
  cursor: pointer;
  transition: 0.2s;
}

.profile__edit-btn:hover {
  opacity: 0.6;
}


@media screen and (max-width: 525px) {
  .profile__edit-btn {
    min-width: 18px;
    height: 18px;
    background-size: 7.5px;

  }
}
