.form__tooltip {
  margin: 0;
  padding: 0;
  font-size: 24px;
  line-height: 29px;
  font-weight: 900;
  text-align: center;
}

@media screen and (max-width: 525px) {
  .form__tooltip {
    font-size: 18px;
    line-height: 22px;
  }
}