.header__logo {
  display: block;
  width: 142px;
  height: 34px;
  /* object-fit: cover; */

}

@media screen and (max-width: 525px) {
  .header__logo {
    width: 104px;
    /* object-fit: contain; */
  }
}
