.profile__name {
  margin: 0 17px 0 0;
  padding: 0;
  font-size: 42px;
  font-weight: 500;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/*
@media screen and (max-width: 768px) {
  .profile__name {
    font-size: calc(1.953125vw + 22px);
  }
} */


@media screen and (max-width: 525px) {
  .profile__name {
    margin-right: 7px;
    font-size: 27px;
  }
}
