.card__trash-btn_enabled {
  display: block;
  position: absolute;
  right: 15px;
  top: 18px;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  background-image: url('../../../../images/Trash_white.svg');
  width: 18px;
  height: 19.3px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  outline: none;
  transition: 0.2s;
}

.card__trash-btn_enabled:hover {
  opacity: 0.6;
}
