.figure__caption {
  position: absolute;
  left: 0;
  bottom: -10px;
  transform: translateY(100%);
  margin: 0;
  padding: 0;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  max-height: 12.5vh;
  overflow: auto;
}
